<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight bbox plr13">
      <!-- 头像 -->
      <div class="avatar-uploader pt20 mb20 textc">
        <img :src="member.logo"
             class="avatar"
             onerror="this.src='https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2100401123,2895311668&fm=26&gp=0.jpg'" />
        <div class="text111 f15 mt10">{{ member.name }}</div>
      </div>
      <!-- 菜单列表 -->
      <div class="menuList">
        <div class="menuITem flex align-center justify-between"
             @click="jump('/personalInfo')">
          <div>
            <img src="../../assets/my/info.png"
                 alt=""
                 width="17"
                 height="17"
                 class="vm" />
            <span class="f15 text111 vm pl5">{{
              $t("navbar.personalData")
            }}</span>
          </div>
          <i class="el-icon-arrow-right f16"></i>
        </div>
        <div class="menuITem flex align-center justify-between"
             @click="jump('/accountInfo')">
          <div>
            <img src="../../assets/my/info.png"
                 alt=""
                 width="17"
                 height="17"
                 class="vm" />
            <span class="f15 text111 vm pl5">{{
              $t("navbar.accountInfo")
            }}</span>
          </div>
          <i class="el-icon-arrow-right f16"></i>
        </div>
        <div class="menuITem flex align-center justify-between"
             @click="jump('/myMessage')">
          <div>
            <img src="../../assets/my/message.png"
                 alt=""
                 width="17"
                 height="17"
                 class="vm" />
            <span class="f15 text111 vm pl5">{{ $t("navbar.myMessage") }}</span>
          </div>
          <i class="el-icon-arrow-right f16"></i>
        </div>
        <div class="menuITem flex align-center justify-between"
             @click="jump('/trainingTest')">
          <div>
            <img src="../../assets/my/test.png"
                 alt=""
                 width="17"
                 height="17"
                 class="vm" />
            <span class="f15 text111 vm pl5">{{
              $t("navbar.trainingTest")
            }}</span>
          </div>
          <i class="el-icon-arrow-right f16"></i>
        </div>
        <div class="menuITem flex align-center justify-between"
             @click="jump('/testCenter')">
          <div>
            <img src="../../assets/my/eaxm.png"
                 alt=""
                 width="17"
                 height="17"
                 class="vm" />
            <span class="f15 text111 vm pl5">{{
              $t("navbar.testCenter")
            }}</span>
          </div>
          <i class="el-icon-arrow-right f16"></i>
        </div>
        <div class="menuITem flex align-center justify-between"
             @click="jump('/testRecord')">
          <div>
            <img src="../../assets/my/eaxm.png"
                 alt=""
                 width="17"
                 height="17"
                 class="vm" />
            <span class="f15 text111 vm pl5">{{
              $t("navbar.testRecord")
            }}</span>
          </div>
          <i class="el-icon-arrow-right f16"></i>
        </div>
        <div class="menuITem flex align-center justify-between"
             @click="jump('/favorites')">
          <div>
            <img src="../../assets/my/love.png"
                 alt=""
                 width="17"
                 height="17"
                 class="vm" />
            <span class="f15 text111 vm pl5">{{ $t("navbar.favorites") }}</span>
          </div>
          <i class="el-icon-arrow-right f16"></i>
        </div>
        <div class="menuITem flex align-center justify-between"
             @click="jump('/browserHistory')">
          <div>
            <img src="../../assets/my/browse.png"
                 alt=""
                 width="17"
                 height="17"
                 class="vm" />
            <span class="f15 text111 vm pl5">{{
              $t("navbar.browserHistory")
            }}</span>
          </div>
          <i class="el-icon-arrow-right f16"></i>
        </div>
        <div class="menuITem flex align-center justify-center">
          <div>
            <span class="f15 text111 vm pl5"
                  @click="loginOut">{{
              $t("navbar.logOut")
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
export default {
  components: { Header, Footer },
  data () {
    return {
      imageUrl:
        "https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2100401123,2895311668&fm=26&gp=0.jpg", // 头像
      menuList: [
        {
          id: 1,
          name: "个人资料",
          icon: require("../../assets/my/info.png"),
          link: "/personalInfo",
        },
        {
          id: 2,
          name: "账号信息",
          icon: require("../../assets/my/info.png"),
          link: "/accountInfo",
        },
        {
          id: 3,
          name: "我的消息",
          icon: require("../../assets/my/message.png"),
          link: "/myMessage",
        },
        {
          id: 4,
          name: "培训测试",
          icon: require("../../assets/my/test.png"),
          link: "/trainingTest",
        },
        {
          id: 5,
          name: "考试中心",
          icon: require("../../assets/my/eaxm.png"),
          link: "/testCenter",
        },
        {
          id: 6,
          name: "考试记录",
          icon: require("../../assets/my/eaxm.png"),
          link: "/testRecord",
        },
        {
          id: 7,
          name: "收藏夹",
          icon: require("../../assets/my/love.png"),
          link: "/favorites",
        },
        {
          id: 8,
          name: "浏览记录",
          icon: require("../../assets/my/browse.png"),
          link: "/browserHistory",
        },
      ],
      member: {},
    };
  },
  methods: {
    // 菜单跳转
    jump (path) {
      this.$router.push(path);
    },
    loginOut () {
      this.$router.push('/login');
      localStorage.clear()
    }
  },
  created () {
    this.member = JSON.parse(localStorage.getItem("member"));
  },
};
</script>

<style scoped>
.avatar {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  object-fit: cover;
}
.menuITem {
  border-bottom: 1px solid #eeeeee;
  padding: 15px 0;
}
.menuITem:last-of-type {
  border-bottom: none;
}
</style>